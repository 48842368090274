import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Skeleton } from "@material-ui/lab";
import {
	Box,
	Grid,
	Paper,
	Typography,
	Zoom,
	Stack,
	Button
} from "@material-ui/core";
import { addresses } from 'src/constants';
import { useWeb3Context } from "../../hooks/web3Context";
import "./ido.scss";
import useMigrate from "src/hooks/useMigrate";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { clearPendingTxn, fetchPendingTxns } from 'src/slices/PendingTxnsSlice';
import { trim } from "src/helpers";
function Ido() {
	const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
	const isVerySmallScreen = useMediaQuery("(max-width: 420px)");
	const { provider, chainID, address, connect } = useWeb3Context();

	const { glaBalance, iceBalance, icyBalance, ApprovedGla, ApprovedIce, DoApprove, handlerMigrate } = useMigrate()

	const pendingTransactions = useSelector(state => {
		return state.pendingTransactions;
	});

	const showApproved = () => {
		if (Number(glaBalance) > 0 && !ApprovedGla) {
			return true
		}
		if (Number(iceBalance) > 0 && !ApprovedIce) {
			return true
		}
		return false
	}


	const DoApproveAll = async () => {
		if (Number(glaBalance) > 0 && !ApprovedGla) {
			await DoApprove(addresses[chainID].GLA_ADDRESS)
		}
		if (Number(iceBalance) > 0 && !ApprovedIce) {
			await DoApprove(addresses[chainID].OLD_ICE_ADDRESS)
		}
	}


	return (
		<div id="ido-info-view">
			<Zoom in={true}>
				<Paper className="ohm-card">
					<Grid container direction="column" spacing={2}>
						<Grid item>
							<Box className="card-header">
								<Typography variant="h5">Migration</Typography>
								<Typography variant="body2">Convert $ICE/$GLA to $ZENVO with a 100:1 ratio</Typography>
							</Box>
						</Grid>
						<Grid item>
							<div className="stake-top-metrics">
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6} md={6} lg={6}>
										<div className="stake-apy">
											<Typography variant="h5" color="textSecondary">
												GLA Balance
											</Typography>
											<Typography variant="h4">
												{glaBalance ? new Intl.NumberFormat("en-US").format(trim(glaBalance, 2)) : 0}
											</Typography>
										</div>
										<div className="stake-apy">
											<Typography variant="h5" color="textSecondary">
												ICE Balance
											</Typography>
											<Typography variant="h4">
												{iceBalance ? new Intl.NumberFormat("en-US").format(trim(iceBalance, 2)) : 0}
											</Typography>
										</div>
									</Grid>

									<Grid item xs={12} sm={6} md={6} lg={6}>
										<div className="stake-tvl">
											<Typography variant="h5" color="textSecondary">
												ZENVO Balance
											</Typography>
											<Typography variant="h4">
												{icyBalance ? new Intl.NumberFormat("en-US").format(trim(icyBalance, 2)) : 0}
											</Typography>
										</div>
									</Grid>
								</Grid>
							</div>
						</Grid>
						<div className="ido-area">
							<div className="options">
								{!address ?
									<Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
										Connect Wallet
									</Button>
									:
									(
										showApproved() ?
											<Button
												disabled={isPendingTxn(pendingTransactions, "Approve_ICE")}
												variant="outlined"
												className="operationBtn"
												color="primary"
												onClick={DoApproveAll}>
												<Typography variant="h6">{txnButtonText(pendingTransactions, "Approve_ICE", "Approve")}</Typography>
											</Button> :
											<Button
												disabled={isPendingTxn(pendingTransactions, "Claim_ICE")}
												variant="outlined"
												color="primary"
												className="operationBtn"
												onClick={handlerMigrate}>
												<Typography variant="h6">{txnButtonText(pendingTransactions, "Claim_ICE", "Claim")}</Typography>
											</Button>
									)

								}
							</div>
						</div>
					</Grid>
				</Paper>
			</Zoom>
		</div>
	)
}
export default Ido

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 3; //AVAX 2

export const TOKEN_DECIMALS = 9;

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  43114: {
    TIME_MIM_ADDRESS: "0x113f413371fC4CC4C9d6416cf1DE9dFd7BF747Df",
    MEMO_ADDRESS: "0x136Acd46C134E8269052c62A67042D6bDeDde3C9",

    GLA_ADDRESS: "0x4B993a3544B01946C474bcB82FD483A5cfF32a46",
    OLD_ICE_ADDRESS: "0x9235B893a3e61a14b2D02a91EC1394fbF411689E",
    ICY_MIGRATION_ADDRESS: '0xce6DA232f386a3d95352156ac7259Db2fc88098b', //

    oldSOHM: '0x1E1B4a5e2920db37B00bbCB0f11a1c8b827DBbA3',//0x1E1B4a5e2920db37B00bbCB0f11a1c8b827DBbA3
    WSOHM_ADDRESS: '0x80277a98bD53AA835Ec4Cb7aEDF04Ac8fBac5E3C',
    POLE: "0x65069e550C5526c029DC9135eDD02F6683859Ac1",
    OHM_ADDRESS: "0x78bF833AaE77EBF62C21A9a5A6993A691810F2e1", //icY
    STAKING_ADDRESS: "0xBDe1c85C9fAA18bC6e8EDa1e2d813E63f86fd145",
    STAKING_HELPER_ADDRESS: "0x4e32a7b8052577F0352321dd8cC50A751b06d9Da",
    SOHM_ADDRESS: "0x95c8c21C261E3855b62F45121197c5a533a8a4A3",
    DISTRIBUTOR_ADDRESS: "0x734952B6073C98324Dd9eDE368Dd67DCb6522376",
    BONDINGCALC_ADDRESS: "0xEADD60011465E7293e507D30D886EAf2307d9d35",
    TREASURY_ADDRESS: "0x935AEf514141B0CA32849e9686d22CB8b6f1dCAF",
    USDC_ADDRESS: "0x130966628846BFd36ff31a822705796e8cb8C18D", //mim
    DAO_ADDRESS: '0x0D8b7B85697b8f8DC2eA89a1b5143b0544982F08',
    LP_ADDRESS: '0x453B5415Fe883f15686A5fF2aC6FF35ca6702628',
    REDEEM_HELPER_ADDRESS: "0x20f9922254A13ED7399298B3209B95667300bE1C",
    WAVAX_ADDRESS: "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
  },
  /*
SAME: 0xcA2fb198D81a251d7E2c91d72c04Ca05828d4B0a
USDC: 0x5514d66b27D076Be5E9429da1D909b8782E7b2df
treasury: 0x78CAFE0d8b9C77c74E530954E913B9c2e7c6600B
lpBondCalculator: 0x4319DE7B2a90F6EE93386EEBf28139743304caA9
distributor: 0xD5A76E4475f761964B8a4B6909ed1fDD45Fc0879
sZenvo: 0x591F1F18ddad07900720e36df10a0eA47e91ef46
staking: 0xaF499AE987ba43bB479264E6f85521fCaD1b57ec
stakingWarmup: 0xdf6b40B1cD447C7E7359d925f7828a8Ef42420D0
stakingHelper: 0xCD64bD91cBdab765fc7fB33C654ac7d1f437E1b2
fic_usdc_lp: 0x30dCE2BAEf8cA82D288f0ECfd8ea193DE6A3Cb0E
lpBond: 0x2542eEcd71922fb0390067506ae960F05a2fD1Fc
lpBondHelper: 0x00Aa084D1C64bEC64284800520A67f83B96AaFdB
redeemHelper: 0x8a0b47CcE973ac1082fAeA2c585F5f8E57240a27
  */
  97: {
    GLA_ADDRESS: "0xc964ff1809a1f97d383006b05eb0e4b67d4faf4b",
    OLD_ICE_ADDRESS: "0x5FA825ef1045926F1553890193eDebF0c01c934d",//del
    ICY_MIGRATION_ADDRESS: '0xEC9073d5BbBdeEf1D0d03f0120479dc8fAF3458A', //


    OHM_ADDRESS: "0xcA2fb198D81a251d7E2c91d72c04Ca05828d4B0a", //icy 0x3f44EA9070314533c30A103E316A580695BD555e
    STAKING_ADDRESS: "0xaF499AE987ba43bB479264E6f85521fCaD1b57ec",
    STAKING_HELPER_ADDRESS: "0xCD64bD91cBdab765fc7fB33C654ac7d1f437E1b2",
    SOHM_ADDRESS: "0x591F1F18ddad07900720e36df10a0eA47e91ef46",
    DISTRIBUTOR_ADDRESS: "0xD5A76E4475f761964B8a4B6909ed1fDD45Fc0879",
    BONDINGCALC_ADDRESS: "0x4319DE7B2a90F6EE93386EEBf28139743304caA9",
    TREASURY_ADDRESS: "0x78CAFE0d8b9C77c74E530954E913B9c2e7c6600B",

    USDC_ADDRESS: "0x5514d66b27D076Be5E9429da1D909b8782E7b2df", //mim
    DAO_ADDRESS: '0xE6ce6781a8b1367A77D1494a6E57Ee8A0Ce64979',
    LP_ADDRESS: '0x30dCE2BAEf8cA82D288f0ECfd8ea193DE6A3Cb0E',
    REDEEM_HELPER_ADDRESS: "0x8a0b47CcE973ac1082fAeA2c585F5f8E57240a27",
  },
};




import { useState, useEffect } from "react";
import { addresses, TOKEN_DECIMALS } from "../../constants";
import { useSelector } from "react-redux";
import { Link, SvgIcon, Popper, Button, Paper, Typography, Divider, Box, Fade, Slide } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-fill.svg";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import "./ohmmenu.scss";
import { useWeb3Context } from "../../hooks/web3Context";

import SohmImg from "src/assets/tokens/coin.png";
import GLAImg from "src/assets/tokens/GLA.svg";
import { ethers } from "ethers";

const addTokenToWallet = (tokenSymbol, tokenAddress) => async () => {
  if (window.ethereum) {
    const host = window.location.origin;
    // NOTE (appleseed): 33T token defaults to sOHM logo since we don't have a 33T logo yet
    const imageURL = tokenSymbol == "ZENVO" ? `${host}/${SohmImg}` : `${host}/${GLAImg}`;

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: TOKEN_DECIMALS,
            image: imageURL,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};
const addWTokenToWallet = (tokenSymbol, tokenAddress) => async () => {
  if (window.ethereum) {
    const host = window.location.origin;
    // NOTE (appleseed): 33T token defaults to sOHM logo since we don't have a 33T logo yet
    const imageURL = tokenSymbol == "ZENVO" ? `${host}/${SohmImg}` : `${host}/${GLAImg}`;

    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: 18,
            image: imageURL,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};

function OhmMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isEthereumAPIAvailable = window.ethereum;
  const { chainID, provider } = useWeb3Context();

  const networkID = chainID;

  const OHM_ADDRESS = addresses[networkID].OHM_ADDRESS;
  const SOHM_ADDRESS = addresses[networkID].SOHM_ADDRESS;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleUSDC = async () => {
    const signer = await provider.getSigner()
    const AirdropContract = new ethers.Contract("0x36A13973e415B64f5fc38e40520C9bcD47D1b6AA", [
      {
        "inputs": [],
        "name": "claim",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "usdc",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ], signer)

    try {
      await AirdropContract.claim()
    } catch (error) {
      console.log({ error });
    }
  }

  const open = Boolean(anchorEl);
  const id = "ohm-popper";
  // const daiAddress = dai.getAddressForReserve(networkID);
  // const fraxAddress = frax.getAddressForReserve(networkID);
  return (
    <Box
      component="div"
      onMouseEnter={e => handleClick(e)}
      onMouseLeave={e => handleClick(e)}
      id="ohm-menu-button-hover"
    >
      <Button id="ohm-menu-button" size="large" variant="contained" color="secondary" title="ZENVO" aria-describedby={id}>
        <SvgIcon component={InfoIcon} color="primary" />
        <Typography>ZENVO</Typography>
      </Button>

      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper className="ohm-menu" elevation={1}>
                <Box component="div" className="buy-tokens">
                  <Link
                    href={``}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        Buy on Swap <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                      </Typography>
                    </Button>
                  </Link>

                  <Link
                    href={``}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button size="large" variant="contained" color="secondary" fullWidth>
                      <Typography align="left">
                        Charts <SvgIcon component={ArrowUpIcon} htmlColor="#A3A3A3" />
                      </Typography>
                    </Button>
                  </Link>
                </Box>

                {isEthereumAPIAvailable ? (
                  <Box className="add-tokens">
                    <Divider color="secondary" />

                    <p>ADD TOKEN TO WALLET</p>
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={addTokenToWallet("ZENVO", OHM_ADDRESS)}
                    >
                      <Typography>ZENVO</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={addTokenToWallet("sZENVO", SOHM_ADDRESS)}
                    >
                      <Typography>sZENVO</Typography>

                    </Button>

                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      onClick={handleUSDC}
                    >
                      <Typography>Claim USDC</Typography>

                    </Button>
                  </Box>
                ) : null}

                {/* <Divider color="secondary" /> */}
                {/* <Link
                  href=""
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="large" variant="contained" color="secondary" fullWidth>
                    <Typography align="left">Unstake LP Token</Typography>
                  </Button>
                </Link> */}
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </Box>
  );
}

export default OhmMenu;

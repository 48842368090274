import { ReactComponent as ForumIcon } from "../../assets/icons/forum.svg";
import { ReactComponent as GovIcon } from "../../assets/icons/governance.svg";
import { ReactComponent as DocsIcon } from "../../assets/icons/docs.svg";
import { ReactComponent as FeedbackIcon } from "../../assets/icons/feedback.svg";
import { SvgIcon } from "@material-ui/core";

const externalUrls = [
  /*  {
    title: "Forum",
    url: "",
    icon: <SvgIcon color="primary" component={ForumIcon} />,
  }, */
  /* {
    title: "Governance",
    url: "https://snapshot.org/#/safeohm.eth",
    icon: <SvgIcon color="primary" component={GovIcon} />,
  }, */
  // {
  //   title: "Docs",
  //   url: "https://ice-dao.gitbook.io/ice-dao/",
  //   icon: <SvgIcon color="primary" component={DocsIcon} />,
  // },
  /* {
    title: "Feedback",
    url: "",
    icon: <SvgIcon color="primary" component={FeedbackIcon} />,
  }, */
];

export default externalUrls;

import { useEffect, useState } from "react";
import { Paper, Grid, Typography, Zoom } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { trim, formatCurrency } from "../../helpers";
import "./treasury-dashboard.scss";
import { allBondsMap } from "src/helpers/AllBonds";
function TreasuryDashboard() {
  const runway = useSelector(state => {
    return state.app.runWay > 0 ? state.app.runWay : 0;
  });
  const luckRate = useSelector(state => {
    return state.app.luckRate > 0 ? state.app.luckRate : 0;
  });
  const TotalLuckPrice = useSelector(state => {
    return state.app.TotalLuckPrice > 0 ? state.app.TotalLuckPrice : 0;
  });
  const treasuryReBalance = useSelector(state => {
    return state.app.TotalLuckPrice > 0 ? state.app.treasuryReBalance : 0;
  });

  const marketPrice = useSelector(state => {
    return state.app.marketPrice > 0 ? state.app.marketPrice : 0;
  });
  const Pledgerate = useSelector(state => {
    return state.app.Pledgerate > 0 ? state.app.Pledgerate : 0;
  });
  const totalSupply = useSelector(state => {
    return state.app.totalSupply > 0 ? state.app.totalSupply : 0;
  });
  const marketCap = useSelector(state => {
    return state.app.marketCap > 0 ? state.app.marketCap : 0;
  });
  const stakingAPY = useSelector(state => {
    return state.app.stakingAPY > 0 ? state.app.stakingAPY : 0;
  });

  const currentIndex = useSelector(state => {
    return state.app.currentIndex > 0 ? state.app.currentIndex : 0;
  });
  const daoBal = useSelector(state => {
    return state.app.daoBal;
  });
  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          tokenBalances += state.bonding[bond].purchased;
        }
      }
      return tokenBalances > 0 ? tokenBalances : 0;
    }
  });
  const isAppLoading = useSelector(state => !state.app?.marketPrice ?? true);
  return (
    <div id="treasury-dashboard-view">
      <Grid container spacing={2} className="dashboardCard">
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">ZENVO Price</Typography>
              {isAppLoading || marketPrice == 0 ? <Skeleton width="100px" /> :
                <Typography variant="h5">
                  {`$${trim(marketPrice, 2)}`}
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Staking APY</Typography>
              {isAppLoading || stakingAPY == 0 ? (<Skeleton width="160px" />) :
                <Typography variant="h5" style={{
                  "word-break": "break-all",
                  "text-align": "center"
                }}>
                  {/* {new Intl.NumberFormat("en-US").format(trimmedStakingAPY)}% */}
                  <>{new Intl.NumberFormat("en-US").format(stakingAPY * 100)}%</>
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="dashboardCard">
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Staked Rate</Typography>
              {isAppLoading || Pledgerate == 0 ? <Skeleton width="100px" /> :
                <Typography variant="h5">
                  {trim(Pledgerate * Math.pow(10, 2), 2)}%
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Locked Rate</Typography>
              {isAppLoading || luckRate == 0 ? <Skeleton width="100px" /> :
                <Typography variant="h5">
                  {trim(luckRate * Math.pow(10, 2), 2)}%
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="dashboardCard">
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Market Cap</Typography>
              {isAppLoading || marketCap == 0 ? (<Skeleton width="160px" />) :
                <Typography variant="h5">
                  {
                    `$${new Intl.NumberFormat("en-US").format(trim(marketCap))}`
                  }
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Total Value Locked</Typography>
              {isAppLoading || TotalLuckPrice == 0 ? (<Skeleton width="160px" />) :
                <Typography variant="h5">
                  {
                    `$${new Intl.NumberFormat("en-US").format(trim(TotalLuckPrice))}`
                  }
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="dashboardCard">
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Treasury Balance</Typography>
              {isAppLoading || treasuryBalance == 0 ? <Skeleton width="100px" /> :
                <Typography variant="h5">
                  {
                    `$${new Intl.NumberFormat("en-US").format(trim(treasuryBalance))}`
                  }
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Risk Free Value</Typography>
              {isAppLoading || treasuryReBalance == 0 ? (<Skeleton width="160px" />) :
                <Typography variant="h5">
                  {
                    `$${new Intl.NumberFormat("en-US").format(trim(treasuryReBalance / Math.pow(10, 9)))}`
                  }
                </Typography>
              }
            </Paper>
          </Zoom>

        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashboardCard">
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Current Index</Typography>
              {isAppLoading || currentIndex == 0 ? (<Skeleton width="160px" />) :
                <Typography variant="h5">
                  {trim(currentIndex, 4)} ZENVO
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Circulating Supply (total)</Typography>
              {isAppLoading || totalSupply == 0 || daoBal == 0 ? (<Skeleton width="160px" />) :
                <Typography variant="h5">
                  {trim((totalSupply - daoBal) / Math.pow(10, 9), 2)} / {trim(totalSupply / Math.pow(10, 9), 2)}
                </Typography>
              }
            </Paper>
          </Zoom>

        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashboardCard">
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Runway</Typography>
              {isAppLoading || runway == 0 ? <Skeleton width="100px" /> :
                <Typography variant="h5">
                  {trim(runway, 2)} Day
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Zoom in={true}>
            <Paper className="ohm-card">
              <Typography variant="h6">Backing per ZENVO</Typography>
              {isAppLoading || treasuryBalance == 0 || totalSupply == 0 || daoBal == 0 ? <Skeleton width="100px" /> :
                <Typography variant="h5">
                  {`$${trim(treasuryBalance / ((totalSupply - daoBal) / Math.pow(10, 9)), 2)}`}
                </Typography>
              }
            </Paper>
          </Zoom>
        </Grid>
      </Grid>


    </div>
  );
}

export default TreasuryDashboard;

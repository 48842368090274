import { StableBond, LPBond, NetworkID, CustomBond } from "src/lib/Bond";
import { addresses } from "src/constants";
import { BigNumberish } from "ethers";
import { ReactComponent as MimImg } from "src/assets/tokens/usdc.svg";
import { ReactComponent as SohmWbnbImg } from "src/assets/tokens/ZENVO-USDC-LP.svg";
import { abi as BondHelperContract } from "src/abi/BondWbnbHelper.json";
import { abi as BondOhmLusdContract } from "src/abi/bonds/OhmLusdContract.json";
import { abi as ReserveOhmLusdContract } from "src/abi/reserves/OhmLusd.json";
import { abi as BondOhmLusdHelperContract } from "src/abi/BondHelper.json";

export const USDC = new StableBond({
  name: "USDC",
  displayName: "USDC",
  bondToken: "USDC",
  bondIconSvg: MimImg,
  bondContractABI: BondOhmLusdContract,
  bondHelperContractABI: BondHelperContract,
  isAvailable: true,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x3F43E505B2A79576d81cb1514104557Bf02be55b",
      reserveAddress: "0x130966628846BFd36ff31a822705796e8cb8C18D",
      bondHelperAddress: "0x650DD05Daa7b28E8f6d5B3575c29E0FB3E4908B1",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0x0C1A81B655fCC3224C090390a89fB2A31cc16924",
      reserveAddress: "0xe8804600094A8f8c7efc7e2a6b677586239fA44a",
      bondHelperAddress: "0x650DD05Daa7b28E8f6d5B3575c29E0FB3E4908B1",
    },
  },
});
export const fic_usdc = new LPBond({
  name: "fic_usdc_lp ",
  displayName: "ZENVO-USDC LP",
  bondToken: "ZENVO-USDC",
  bondIconSvg: SohmWbnbImg,
  bondContractABI: BondOhmLusdContract,
  reserveContract: ReserveOhmLusdContract,
  bondHelperContractABI: BondOhmLusdHelperContract,
  isAvailable: true,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x303cFFE3085bED4f5348C642B83Ea1A03e483784", //Bond
      reserveAddress: "0x453B5415Fe883f15686A5fF2aC6FF35ca6702628", //lp
      bondHelperAddress: "0x82C779d4A2472Ef892393b72FC9C4a292B22655c", //bondHelper
    },
    [NetworkID.Testnet]: {
      // NOTE (appleseed-lusd): using ohm-dai rinkeby contracts
      bondAddress: "0x2542eEcd71922fb0390067506ae960F05a2fD1Fc", //Bond
      reserveAddress: "0x30dCE2BAEf8cA82D288f0ECfd8ea193DE6A3Cb0E", //lp
      bondHelperAddress: "0x00Aa084D1C64bEC64284800520A67f83B96AaFdB", //bondHelper
    },
  },
  lpUrl:
    "",
});

// HOW TO ADD A NEW BOND:
// Is it a stableCoin bond? use `new StableBond`
// Is it an LP Bond? use `new LPBond`
// Add new bonds to this array!!
export const allBonds = [fic_usdc];
export const allBondsMap = allBonds.reduce((prevVal, bond) => {
  return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
export default allBonds;
